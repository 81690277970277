import { Typography, Box } from "@material-ui/core";
import Polaroid from "polaroid-image";
import m6 from "../../assets/m6.png";
import m10 from "../../assets/m10.jpeg";
import m12 from "../../assets/m12.png";

interface Props {}

export default function AboutUs({}: Props) {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Box
        width="90%"
        bgcolor="rgba(255, 255, 255, 0.7)"
        height="70%"
        mt="3%"
        ml="5%"
        borderRadius="35px"
      >
        <Box display="flex">
          <Box>
            <Box pt="5%" pl="10%">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h2"
              >
                Avem lapte tot
              </Typography>
            </Box>
            <Box pl="25%">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h2"
              >
                timpul anului
              </Typography>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              maxWidth="80%"
              pl="10%"
              pt="3%"
              pb="4%"
            >
              <Typography
                style={{
                  fontFamily: "Love Ya Like A Sister",
                }}
                variant="body1"
              >
                Totul a inceput de la cat de sanatos ne putem hrani. In anul
                2015 aveam o microferma si, printre sute de animale, o magarita,
                Puica. In prezent detinem peste 100 de magarite, diferite ca
                personalitate, mame bune, sanatoase, majoritatea foarte
                prietenoase. Ele dau nastere si isi alapteaza puii in perioade
                diferite unele fata de celelalte, astfel ca la ferma noastra
                veti gasi lapte proaspat muls pe tot parcursul anului.
              </Typography>
            </Box>
          </Box>

          <Box mt="2.5%" mr="5%">
            <video
              src="https://mogosoaia-donkey-farm.s3.eu-central-1.amazonaws.com/video-1626949671.mp4"
              controls
              width="550px"
              style={{ borderRadius: "15px" }}
            />
          </Box>
        </Box>
      </Box>
      <Box bgcolor="rgba(255, 255, 255, 1)" mt="7%">
        <Box display="flex">
          <Box pt="5%" pl="2%" mb="4%">
            <Polaroid
              imgSrc={m6}
              zoom="false"
              rotate="-15"
              frameHeight="100%"
              imageHeight="70%"
              frameWidth="100%"
              //imageWidth="230px"
              hoverFrameShadow="false"
            />
          </Box>
          <Box>
            <Box pt="5%" pl="7%">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h5"
              >
                Ne desfasuram activitatea de {currentYear - 2015} ani
              </Typography>
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              maxWidth="90%"
              pl="7%"
              pt="1.5%"
            >
              <Typography
                style={{
                  fontFamily: "Love Ya Like A Sister",
                }}
                variant="body1"
              >
                Din dorinta de a avea o alimentatie cat mai sanatoasa, care sa
                includa ingrediente naturale, am descoperit acest aliment
                minune, ale carui beneficii nu se lasa asteptate. Astfel, am
                decis sa aducem acest produs in casele cat mai multor oameni,
                asigurandu-ne ca laptele livrat provine de la animale sanatoase
                si fericite, crescute in aer liber si cu multa dragoste.
              </Typography>
              <Box mt="1%">
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Vindem de {currentYear - 2015} ani acest lapte crud de
                  magarita, neprocesat si ambalat in conditii de igiena. Ba
                  chiar planuim sa aducem acest lapte si in farmacii, la fel de
                  crud si proaspat cum il mulgem! Ne place ce facem si ne
                  bucuram de fiecare moment din aceasta munca, numita cresterea
                  si inmultirea animalelor.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          <Box>
            <Box pt="4%" pl="7%">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h5"
              >
                Laptele de magarita - aliment - medicament
              </Typography>
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              maxWidth="90%"
              pl="7%"
              pt="1.5%"
            >
              <Typography
                style={{
                  fontFamily: "Love Ya Like A Sister",
                }}
                variant="body1"
              >
                Laptele de magarita este cunoscut inca din antichitate pentru
                proprietatile sale uimitoare asupra sanatatii insa, recent a
                revenit in atentia comunitatii medicale datorita tolerabilitatii
                sale inalte in randul copiilor alergici la proteina din lapte de
                vaca precum si a beneficiilor generale asupra sistemului
                imunitar, atat la bebelusi si copii cat si la adulti.
              </Typography>
              <Box mt="1%">
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Cercetatorii de la Universitatea din Palermo si de la cea din
                  Torino (Italia) au desfasurat studii clinice si in vitro inca
                  din 2002, demonstrand ca laptele de magarita este foarte bine
                  tolerat de peste peste 86% din copiii cu alergie severa la
                  proteina din lapte de vaca mediata IgE (reactii cutanate si
                  respiratorii) si de 100% dintre copiii cu alergie non-mediata
                  IgE (tulburari gastrointestinale).
                </Typography>
              </Box>
              <Box mt="1%">
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Asfel, el devine nu numai un tratament natural pentru una
                  dintre cele mai neplacute alergii, dar si un aliment
                  functional esential ce poate inlocui cu succes laptele de vaca
                  sau formulele de lapte praf de crestere in alimentatia
                  bebelusilor si a copiilor. Nu in ultimul rand, este foarte
                  recomandat persoanelor cu sistem imunitar slabit, persoanelor
                  anemice sau sportivilor. V-am convins sa-l incercati? Noi
                  speram ca da, intrucat ne propunem sa incurajam cat mai multi
                  oameni sa incerce laptele crud de magarita!
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box mr="5%">
            <Polaroid
              imgSrc={m10}
              zoom="false"
              frameHeight="110%"
              imageHeight="70%"
              frameWidth="100%"
              //imageWidth="230px"
              hoverFrameShadow="false"
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box mb="7%" ml="2%" mt="2%">
            <Polaroid
              imgSrc={m12}
              zoom="false"
              rotate="15"
              frameHeight="110%"
              imageHeight="70%"
              frameWidth="100%"
              //imageWidth="230px"
              hoverFrameShadow="false"
            />
          </Box>
          <Box>
            <Box pt="5%" pl="70px">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h5"
              >
                Livrarea se face prin ridicare personala
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" maxWidth="90%" pl="80px" pt={3}>
              <Typography
                style={{
                  fontFamily: "Love Ya Like A Sister",
                }}
                variant="body1"
              >
                Daca doriti sa achizitionati laptele de magarita, noi vi-l punem
                la dispozitie atat in varianta de 0.5 litri, la pretul de 50 de
                lei, cat si in varianta de un litru, la pretul de 100 de lei.
                Produsele trebuie ridicate personal de catre cumparator din
                incinta fermei, cu un telefon in prealabil. Ne gasiti chiar in
                vecinatatea Bucurestiului, la Mogosoaia! Va stam la dispozitie
                pentru orice intrebari si informatii suplimentare, la numarul de
                telefon: 0766 509 682.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
