import { Typography, Box, Link } from "@material-ui/core";
import Polaroid from "polaroid-image";
import CheckIcon from "@material-ui/icons/Check";
import m1 from "../../assets/m1.png";
import m11 from "../../assets/m11.jpeg";
import m4 from "../../assets/m4.png";
import m3 from "../../assets/m3.png";
import m7 from "../../assets/m7.png";
interface Props {}

export default function HomeMobile({}: Props) {
  return (
    <>
      <Box
        width="90%"
        bgcolor="rgba(255, 255, 255, 0.7)"
        height="70%"
        mt="3%"
        ml="5%"
        borderRadius="35px"
      >
        <Box display="flex">
          <Box>
            <Box pt="5%" pl="10%">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h3"
              >
                Ferma de magarite
              </Typography>
            </Box>
            <Box pl="25%">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h3"
              >
                Mogosoaia
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" maxWidth="80%" pl="10%" pt="3%">
              <Typography
                style={{
                  fontFamily: "Love Ya Like A Sister",
                }}
                variant="body1"
              >
                Vindem lapte proaspat de magarita, muls si ambalat in conditii
                de igiena. Detinem certificat de producator si un activ de peste
                100 de magarite. Animalele noastre sunt vaccinate si sanatoase,
                detinem certificat de platitori de impozite pentru pasuni,
                culturi și animale. Livrarea se face la ferma din Mogosoaia, in
                orice zi, cu un telefon in prealabil. Pretul este 100 lei/ litru
                sau 50 lei/ 0,5 litri.
              </Typography>
            </Box>
          </Box>
          {/* 
            <Box mt="10%" mr="5%">
              <Polaroid
                imgSrc={m11}
                zoom="false"
                rotate="-15"
                frameHeight="110%"
                imageHeight="70%"
                frameWidth="100%"
                // imageWidth="230px"
                hoverFrameShadow="false"
              />
            </Box>
          </Box> */}
        </Box>
      </Box>
      <Box
        bgcolor="rgba(255, 255, 255, 1)"
        mt="7%"
        width="100%"
        overflow="hidden"
      >
        <Box display="flex">
          <Box>
            <Box mt="4%" mr="5%">
              <Polaroid
                imgSrc={m1}
                zoom="false"
                // rotate="15"
                frameHeight="110%"
                imageHeight="70%"
                frameWidth="90%"
                //imageWidth="230px"
                hoverFrameShadow="false"
              />
            </Box>
            <Box pt="4%" pl="7%">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h5"
              >
                Ce proprietati are laptele de magarita?
              </Typography>
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              maxWidth="90%"
              pl="7%"
              pt="1.5%"
            >
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Laptele de magarita este singurul lapte ce poate fi consumat
                  crud. Este natural hipoalergenic si este tolerat de 90% dintre
                  sugarii cu alergii la unele alimente. Aceasta hipoalergenitate
                  a laptelui de magarita se datoreaza si continutului mic de
                  caseina iar beta-lactoglobulina este doar la 40% din nivelul
                  continut de laptele de vaca.
                </Typography>
              </Box>
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Laptele de magarita nu contine bacterii, ceea ce face ca el sa
                  poată fi baut fara a fi pasteurizat. Substantele naturale din
                  compozitia sa pot creste imunitatea si pot fi de ajutor pentru
                  persoanele cu afectiuni precum astmul, eczema sau psoriazisul.
                  Acest lapte este recomandat copiilor, deoarece prin continutul
                  bogat in calciu ii protejeaza de infectiile gastrointestinale.
                </Typography>
              </Box>
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Laptele de magarita are multa lactoza 7.4g/100g (similar cu
                  cel matern), lactoza ajutand la fixarea calciului in oase. Din
                  acest motiv, el este recomandat si pentru femeile aflate la
                  menopauza. Laptele de magarita este in proportie de 97%
                  similar cu laptele matern, ca si compozitie, proprietati si
                  beneficii asupra sanatatii.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          <Box>
            <Box>
              <Polaroid
                imgSrc={m3}
                zoom="false"
                frameHeight="110%"
                imageHeight="70%"
                frameWidth="90%"
                //imageWidth="230px"
                hoverFrameShadow="false"
              />
            </Box>
            <Box pt="4%" pl="5%">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h5"
              >
                Cine il poate consuma?
              </Typography>
            </Box>

            <Box flexWrap="wrap" maxWidth="90%" pl="5%" pt="1.5%">
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Bebelusii si copiii cu alergie la proteina din lapte de vaca,
                  alergii incrucisate sau alergii multiple (mediate sau
                  non-mediate lgE).
                </Typography>
              </Box>
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Persoanele care sufera de tuse magareasca, tuse alergica, tuse
                  persistenta sau astm.
                </Typography>
              </Box>
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Persoanele cu un sistem imunitar slabit.
                </Typography>
              </Box>
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Persoanele varstnice cu probleme de osteoporoza sau boli de
                  inima.
                </Typography>
              </Box>
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Persoanele la menopauza (ajuta la fixarea calciului in oase).
                </Typography>
              </Box>
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Persoanele care doresc sa aiba o alimentatie bogata in
                  proteine, dar cu continut redus de grasimi.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          <Box>
            <Box>
              <Polaroid
                imgSrc={m7}
                zoom="false"
                //rotate="-15"
                frameHeight="110%"
                imageHeight="70%"
                frameWidth="90%"
                //imageWidth="230px"
                hoverFrameShadow="false"
              />
            </Box>
            <Box pt="7%" pl="7%">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h5"
              >
                Vitamine cat cuprinde
              </Typography>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              maxWidth="90%"
              pl="7%"
              pt="1.5%"
            >
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Laptele de magarita este bogat in vitaminele A, B1, B2, B6, C,
                  D, E. Este un lapte foarte usor de digerat. De asemenea,
                  contine mai putine grasimi decat laptele de vaca si un nivel
                  ridicat de Omega 3 şi Omega 6, care ajuta la scaderea
                  nivelului de colesterol.
                </Typography>
              </Box>
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Contine calciu, magneziu si imunoglobulina.
                </Typography>
              </Box>
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Continutul ridicat de LIZOZIM favorizeaza actiunea selectiva
                  impotriva microorganismelor patogene.
                </Typography>
              </Box>
              <Box display="flex" mb="1%">
                <CheckIcon fontSize="small" />
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                  }}
                  variant="body1"
                >
                  Contine si de 46 de ori mai multa vitamina C decat cel de vaca
                  si de 4 ori mai mult fier si foarte putine grasimi.
                </Typography>
              </Box>
            </Box>
            <Box mt="7%">
              <Polaroid
                imgSrc={m4}
                zoom="false"
                // rotate="15"
                frameHeight="100%"
                imageHeight="70%"
                frameWidth="90%"
                //imageWidth="230px"
                hoverFrameShadow="false"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
