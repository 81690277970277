import { ReactElement } from "react";
import { Typography, Box } from "@material-ui/core";
import Navbar from "../navbar/Navbar";
import logo from "../../assets/logo.png";
import { StyledHeader } from "./Header.style";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";

interface Props {}

export default function Header({}: Props): ReactElement {
  return (
    <StyledHeader>
      <img src={logo} style={{ height: "150px", marginTop: "-21px" }} />
      <Navbar />
      <Box mt={1.3} mr={3}>
        <Box display="flex" style={{ gap: "7px" }}>
          <PhoneIcon />
          <Typography
            style={{ fontFamily: "Love Ya Like A Sister", fontWeight: 600 }}
          >
            0766 509 682
          </Typography>
        </Box>
        <Box display="flex" style={{ gap: "7px" }}>
          <MailIcon />
          <Typography
            style={{ fontFamily: "Love Ya Like A Sister", fontWeight: 600 }}
          >
            fermademagari.mogosoaia@gmail.com
          </Typography>
        </Box>
      </Box>
    </StyledHeader>
  );
}
