import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  padding: 15px 0;
  width: 50%;
`;
export const NavLink = styled(Link)`
  color: #000000;
  align-items: center;
  text-decoration: none;
  //padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #216409;
  }
`;
