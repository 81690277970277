import styled from "styled-components";
import { Typography, Box, Button, MenuItem, Menu } from "@material-ui/core";

export const StyledMenuBar = styled.div`
  background: rgba(255, 255, 255, 0.7);
  height: 80px;
  display: flex;
  justify-content: space-between;
  z-index: 12;
  width: 100%;
  overflow: hidden;
  font-family: "Mate SC", serif;
`;

export const StyledMenuItem = styled(MenuItem)<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? "#3c941c" : "")};
`;
