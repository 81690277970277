import "./App.css";
import { Box } from "@material-ui/core";
import backgroundImg from "./assets/background.jpg";
import Header from "./components/header/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/home/Home";
import HomeMobile from "./components/home/HomeMobile";
import Contact from "./components/contact/Contact";
import AboutUs from "./components/about-us/AboutUs";
import AboutUsMobile from "./components/about-us/AboutUsMobile";
import ContactMobile from "./components/contact/ContactMobile";
import MobileMenuBar from "./components/mobileMenu/MobileMenuBar";
import { isMobile, MobileView, BrowserView } from "react-device-detect";

function App() {
  return (
    <Router>
      <Box
        style={{
          backgroundImage: `url(${backgroundImg})`,
          height: "100%",
          backgroundRepeat: "repeat-y",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          opacity: "0.87",
        }}
      >
        {/* {isMobile ? (
          <> */}
        <MobileView>
          <MobileMenuBar />
          <Switch>
            <Route path="/" exact>
              <HomeMobile />
            </Route>
            <Route path="/despre-noi">
              <AboutUsMobile />
            </Route>
            <Route path="/contact">
              <ContactMobile />
            </Route>
          </Switch>
        </MobileView>
        {/* ) : (
          <> */}
        <BrowserView>
          <Header />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/despre-noi">
              <AboutUs />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
          </Switch>
        </BrowserView>
        {/* )} */}
      </Box>
    </Router>
  );
}

export default App;
