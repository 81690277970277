import { ReactElement } from "react";
import { Nav, NavLink } from "./Navbar.style";
import { Typography, Box } from "@material-ui/core";

interface Props {}

export default function Navbar({}: Props): ReactElement {
  return (
    <>
      <Nav>
        <Box display="flex" justifyContent="space-between" width="100%">
          <NavLink to="/" exact>
            <Typography
              style={{ fontFamily: "Love Ya Like A Sister" }}
              variant="h4"
            >
              acasa
            </Typography>
          </NavLink>
          <NavLink to="/despre-noi">
            <Typography
              style={{ fontFamily: "Love Ya Like A Sister" }}
              variant="h4"
            >
              despre noi
            </Typography>
          </NavLink>
          <NavLink to="/contact">
            <Typography
              style={{ fontFamily: "Love Ya Like A Sister" }}
              variant="h4"
            >
              contact
            </Typography>
          </NavLink>
        </Box>
      </Nav>
    </>
  );
}
