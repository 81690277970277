import styled from "styled-components";

export const StyledHeader = styled.div`
  background: rgba(255, 255, 255, 0.7);
  height: 80px;
  display: flex;
  justify-content: space-between;
  z-index: 12;
  width: 100%;
  font-family: "Mate SC", serif;
`;
