import { Typography, Box, Link } from "@material-ui/core";
import location from "../../assets/location.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";

interface Props {}

export default function Contact({}: Props) {
  return (
    <>
      <Box
        width="90%"
        bgcolor="rgba(255, 255, 255, 0.7)"
        height="70%"
        mt="3%"
        ml="4%"
        borderRadius="35px"
        justifyContent="center"
        overflow="hidden"
        display="flex"
      >
        <Box flexWrap="wrap" pt={3} justifyContent="center">
          <Box>
            <Typography
              style={{ fontFamily: "Love Ya Like A Sister" }}
              variant="h3"
            >
              Unde ne
            </Typography>

            <Box mb="8%">
              <Typography
                style={{ fontFamily: "Love Ya Like A Sister" }}
                variant="h3"
              >
                puteti gasi
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <PhoneIcon
              style={{
                fontSize: 28,
              }}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography
              style={{
                fontFamily: "Love Ya Like A Sister",
                fontWeight: 600,
              }}
              variant="h5"
            >
              0766 509 682
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <MailIcon
              style={{
                fontSize: 28,
              }}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography
              style={{
                fontFamily: "Love Ya Like A Sister",
                fontWeight: 600,
              }}
              variant="body1"
            >
              fermademagari.mogosoaia@gmail.com
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <HomeIcon
              style={{
                fontSize: 28,
              }}
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography
              style={{
                fontFamily: "Love Ya Like A Sister",
                fontWeight: 600,
              }}
              variant="body1"
            >
              Strada Morii, Nr. 9, Mogosoaia
            </Typography>
          </Box>
          <Box
            justifyContent="center"
            display="flex"
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://www.facebook.com/lapteleperfect")
            }
          >
            <FacebookIcon
              style={{
                fontSize: 28,
              }}
            />
          </Box>
          <Box display="flex" justifyContent="center" mb={2}>
            <Typography
              style={{
                fontFamily: "Love Ya Like A Sister",
                fontWeight: 600,
              }}
              variant="body1"
            >
              Laptele Perfect
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box style={{ margin: "5px" }}>
        <img src={location} width="100%" style={{ borderRadius: "15px" }}></img>
      </Box>
      <Box height="29.3vh" mt="5%"></Box>
    </>
  );
}
