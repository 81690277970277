import React, { ReactElement, useState } from "react";
import { Typography, Box, Button, MenuItem, Menu } from "@material-ui/core";
import Navbar from "../navbar/Navbar";
import logo from "../../assets/logo.png";
import { StyledMenuBar, StyledMenuItem } from "./MobileMenu.style";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import { useLocation, useHistory } from "react-router-dom";

interface Props {}
export default function MobileMenuBar({}: Props): ReactElement {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [activeSection, setActiveSection] = useState("acasa");

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledMenuBar>
      <Button onClick={handleClick}>
        <MenuIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "center",
        // }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "center",
        // }}
      >
        <StyledMenuItem
          isActive={activeSection === "acasa"}
          onClick={() => {
            handleClose();
            history.push("/");
            setActiveSection("acasa");
          }}
        >
          <Typography
            style={{ fontFamily: "Love Ya Like A Sister" }}
            variant="h4"
          >
            acasa
          </Typography>
        </StyledMenuItem>
        <StyledMenuItem
          isActive={activeSection === "despre noi"}
          onClick={() => {
            handleClose();
            history.push("/despre-noi");
            setActiveSection("despre noi");
          }}
        >
          <Typography
            style={{ fontFamily: "Love Ya Like A Sister" }}
            variant="h4"
          >
            despre noi
          </Typography>
        </StyledMenuItem>
        <StyledMenuItem
          isActive={activeSection === "contact"}
          onClick={() => {
            handleClose();
            history.push("/contact");
            setActiveSection("contact");
          }}
        >
          <Typography
            style={{ fontFamily: "Love Ya Like A Sister" }}
            variant="h4"
          >
            contact
          </Typography>
        </StyledMenuItem>
      </Menu>
      <img src={logo} style={{ height: "150px", marginTop: "-21px" }} />

      {/* <Navbar /> */}
      {/* <Box mt={1.3} mr={1}>
        <Box display="flex" style={{ gap: "7px" }}>
          <PhoneIcon />
          <Typography
            style={{ fontFamily: "Love Ya Like A Sister", fontWeight: 600 }}
            variant="body2"
          >
            0766 509 682
          </Typography>
        </Box>
        <Box display="flex" style={{ gap: "7px" }}>
          <MailIcon />
          <Typography
            style={{ fontFamily: "Love Ya Like A Sister", fontWeight: 600 }}
          >
            fermademagari.mogosoaia@gmail.com
          </Typography>
        </Box>
      </Box> */}
    </StyledMenuBar>
  );
}
