import { Typography, Box, Link } from "@material-ui/core";
import location from "../../assets/location.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";

interface Props {}

export default function Contact({}: Props) {
  return (
    <>
      <Box
        width="90%"
        bgcolor="rgba(255, 255, 255, 0.7)"
        height="70%"
        mt="3%"
        ml="4%"
        borderRadius="35px"
      >
        <Box display="flex" justifyContent="space-between">
          <Box mt={2}>
            <Box flexWrap="wrap" pl="80px" pt={3}>
              <Box mb="5%">
                <Typography
                  style={{ fontFamily: "Love Ya Like A Sister" }}
                  variant="h2"
                >
                  Unde ne puteti gasi
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <PhoneIcon
                  style={{
                    fontSize: 28,
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                    fontWeight: 600,
                  }}
                  variant="h5"
                >
                  0766 509 682
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <MailIcon
                  style={{
                    fontSize: 28,
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                    fontWeight: 600,
                  }}
                  variant="h5"
                >
                  fermademagari.mogosoaia@gmail.com
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <HomeIcon
                  style={{
                    fontSize: 28,
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                    fontWeight: 600,
                  }}
                  variant="h5"
                >
                  Strada Morii, Nr. 9, Mogosoaia
                </Typography>
              </Box>
              <Box
                justifyContent="center"
                display="flex"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.facebook.com/lapteleperfect")
                }
              >
                <FacebookIcon
                  style={{
                    fontSize: 28,
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="center" mb={2}>
                <Typography
                  style={{
                    fontFamily: "Love Ya Like A Sister",
                    fontWeight: 600,
                  }}
                  variant="h5"
                >
                  Laptele Perfect
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box mt="1%" mr="5%" mb="1%">
            <img
              src={location}
              width="600px"
              style={{ borderRadius: "15px" }}
            ></img>
          </Box>
        </Box>
      </Box>
      <Box height="29.3vh" mt="5%"></Box>
    </>
  );
}
